<template>
  <div class="swiper">
    <!-- <div class="btn"> -->
      <el-button type="primary" @click="addNew">新增</el-button>
    <!-- </div> -->
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="" type="index" align="center" label="序号" > </el-table-column>
        <el-table-column prop="" label="图片" align="center" > 
          <template slot-scope="scope">
             <el-image :src="scope.row.imageUrl" :preview-src-list="[scope.row.imageUrl]" fit='contain' style="width:280px;height:80px;" ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="seq" label="排序(数值大的显示优先)" align="center"> </el-table-column>
        <el-table-column prop="" label="创建时间" align="center"> 
          <template slot-scope="scope">
            {{scope.row.createDt | dataFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" align="center">
          <template slot-scope="scope">
             <el-button type="text" @click="toEdit(scope.row.id)">编辑</el-button>
             <el-popconfirm title="确定删除吗？" @onConfirm="del(scope.row.id)">
               <el-button slot="reference" type="text">删除</el-button>
             </el-popconfirm>
          </template>
        </el-table-column>
    </el-table>

          <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

      tableData:[],
      total:0,
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {}
      },
    };
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
       const data = this.queryInfo
       this.$http.post('/platformBanner/list',data).then(res => {
         if(res.data.code == 200) {
            this.total = res.data.data.count
            this.tableData = res.data.data.data
          }
       })
    },
    del(id){
       let data = {}
       data.id = id
       this.$http.post('/platformBanner/delete',data).then(res => {
         if(res.data.code == 200) {
             this.$notify.success({
                 title: "提示",
                 message: "删除成功",
                });
                this.getList()
         }
       })
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getList();
    },

    toEdit(id){
      this.$router.push({path:'/swiperDetail',query:{id:id}})
    },
    //新增
    addNew(){
       this.$router.push({path:'/swiperDetail'})
    },
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-image-viewer__close{
  color: #fff;
}
.swiper {
  padding: 12px 10px;
}
</style>
